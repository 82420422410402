import React from 'react'
import { Modal } from 'react-bootstrap'
import BasePurpleButtonComponent from './BasePurpleButtonComponent'
import BaseSecondaryButtonComponent from './BaseSecondaryButtonComponent';
import { useSelector } from "react-redux";

export default function ModalMinKmsComponent({
  show,
  setShow,
  kms,
  setIsContinuing,
  setSelectedButton,
  setCurrentView,
  isMobile,
  setIsModalOpen,
  setShowModalForm
}) {

  const { isAuthenticated } = useSelector((state) => state.authReducer);

  const handleClose = () => setShow(false);
  const HandleYesButton = () => {
    setIsContinuing(true)
    if (!isAuthenticated) {
      setShowModalForm(true);
      setIsModalOpen(true);
    } else {
      if (isMobile) {
        setSelectedButton(6);
      } else {
        setShowModalForm(true);
        setCurrentView("step3");
      }
    }
    setShow(false)
  }

  return (
    <Modal show={show} onHide={handleClose} size='sm' centered>
      <Modal.Header style={{ backgroundColor: 'white' }}>
        <p style={{ color: ' #7340d8', fontWeight: 'bold', fontSize: '20px' }}>El kilometraje ingresado es muy bajo</p>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: 'white' }}>
        <p> Ingresaste <strong> {kms}</strong> kms ¿Es correcto?</p>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: 'white' }}>
        <BaseSecondaryButtonComponent buttonText={'No'} onClick={handleClose} />
        <BasePurpleButtonComponent buttonText={'Si'} onClick={HandleYesButton} />
      </Modal.Footer>
    </Modal>
  )
}
